import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import code from './utils/code'
import { getToken } from './utils/auth'
import { Toast } from 'vant';
import 'vant/es/toast/style';
const whileList = ['/', '/view'] // 页面白名单
const app = createApp(App)
app.use(code)
app.use(router)
router.beforeEach((to, from, next) => {

    let token = localStorage.getItem("token");
    if (to.query.uid) {
        localStorage.setItem("uid", to.query.uid);
    }
    if (whileList.includes(to.path) || token) {
        next()
    } else {
        Toast('请登录后操作');
        setTimeout(() => {
            router.push("/");
        }, 1000);
    }
})
app.mount('#app')
