import Cookies from 'js-cookie'
import { getcfg, getOpenIdApi } from "@/api/report";

function getUrlKey(name) {//获取url 参数
    return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ""])[1].replace(/\+/g, '%20')) || null;
}
function getCodeApi(state) {
    getcfg().then(res => {
        //获取code
        let urlNow = encodeURIComponent(window.location.href);
        let scope = 'snsapi_base';    //snsapi_userinfo   //静默授权 用户无感知
        let appid = res.data.wxappid;
        let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${urlNow}&response_type=code&scope=${scope}&state=${state}#wechat_redirect`;
        window.location.replace(url);
    }).catch()
}
const created = () => {
    let isOpenid = Cookies.get('openid')
    if (!isOpenid) {
        let code = getUrlKey("code");
        if (code) {
            getOpenIdApi({ code: code }).then(res => {
                if (res.code == 1) {
                    Cookies.set('openid', res.data.openid);
                }
            }).catch()
        } else {
            getCodeApi("123");
        }
    }
}
export default created  