import request from '@/utils/request'

export function getIndex(data) {
    return request({
        url: '/report/index',
        method: 'post',
        data
    })
}
export function getcfg(data) {
    return request({
        url: '/report/getcfg',
        method: 'post',
        data
    })
}
export function money(data) {
    return request({
        url: '/report/money',
        method: 'post',
        data
    })
}
export function OrderAdd(data) {
    return request({
        url: '/report/OrderAdd',
        method: 'post',
        data
    })
}

export function searchCompany(data) {
    return request({
        url: '/report/searchCompany',
        method: 'post',
        data
    })
}

export function OrderList(data) {
    return request({
        url: '/report/OrderList',
        method: 'post',
        data
    })
}

export function OrderPay(data) {
    return request({
        url: '/report/OrderPay',
        method: 'post',
        data
    })
}

export function Ordersfz(data) {
    return request({
        url: '/report/Ordersfz',
        method: 'post',
        data
    })
}



export function articleList(data) {
    return request({
        url: '/report/articleList',
        method: 'post',
        data
    })
}

export function article(data) {
    return request({
        url: '/report/article',
        method: 'post',
        data
    })
}

// 绑定微信
export function bindwx(data) {
    return request({
        url: '/report/bindwx',
        method: 'post',
        data
    })
}

// 申请退款
export function getRefund(data) {
    return request({
        url: '/report/getRefund',
        method: 'post',
        data
    })
}

export function getAccessToken(data) {
    return request({
        url: '/report/getAccessToken',
        method: 'post',
        data
    })
}

export function getOpenIdApi(data) {
    return request({
        url: '/report/getOpenIdApi',
        method: 'post',
        data
    })
}
export function jssdkSign(data) {
    return request({
        url: '/report/jssdkSign',
        method: 'post',
        data
    })
}
