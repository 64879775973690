import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/home.vue')
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('@/views/news')
  },
  {
    path: '/view',
    name: 'view',
    component: () => import('@/views/view')
  },
  {
    path: '/company',
    name: 'company',
    component: () => import('@/views/company')
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('@/views/search')
  },
  {
    path: '/order',
    name: 'order',
    component: () => import('@/views/order/orderList.vue')
  },
  {
    path: '/reg',
    name: 'register',
    component: () => import('@/components/register')
  }
]
const router = createRouter({
  // history: createWebHistory(),
  history: createWebHashHistory(),
  routes
})

export default router

