import axios from 'axios'

var service = axios.create({
    baseURL: 'https://uuyya.com//api', //接口
    timeout: 5000,
    headers: {
        'Content-Type': 'application/json;charset=UTF-8;',
    }
})
//请求拦截器 
service.interceptors.request.use((config: any) => {
    // 判断是否存在token，如果存在，在http请求的header都加上token
    const token = localStorage.getItem("token");
    token && (config.headers.Token = token)
    //若请求方式为post，则将data参数转为JSON字符串
    if (config.method === 'POST') {
        config.data = JSON.stringify(config.data);
    }
    return config;
});

//响应拦截器
service.interceptors.response.use((res) => {
    //响应成功
    return res.data;
}, (error) => {
    //console.log(error)
    //return error.response.status;
    //响应错误
    if (error.response && error.response.status) {
        const status = error.response.status
        return Promise.reject(status);
    }
    return Promise.reject(error);
});
export default service;